import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Button, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
// less
import './signup.less'
// images
import LogoSrc from 'images/logo.png'

const Signup = () => {
    const { t } = useTranslation()

    const rulesForm = {
        account: [
            { required: true, message: t( 'login.accountPlaceholder' ) }
        ],
        password: [
            { required: true, message: t( 'login.passwordPlaceholder' ) }
        ],
    }
    const handleFormSubmit = ( values ) => {
        console.log( values )
    }

    return (
        <div className="login-container">
            <Card style={ { width: 468 } }>
                <figure>
                    <Link to="/">
                        <img src={ LogoSrc } alt="logo"/>
                    </Link>
                    <figcaption>{ t( 'signup.title' ) }</figcaption>
                </figure>
                <Form
                    name="loginFrom"
                    onFinish={ handleFormSubmit }>
                    <Form.Item name="account" rules={ rulesForm.account }>
                        <Input placeholder={ t( 'login.account' ) }/>
                    </Form.Item>
                    <Form.Item name="password" rules={ rulesForm.password }>
                        <Input placeholder={ t( 'login.password' ) }/>
                    </Form.Item>
                    <Form.Item>
                        <Button style={ { width: '100%' } } type="primary"
                                htmlType="submit">{ t( 'signup.title' ) }</Button>
                    </Form.Item>
                </Form>

            </Card>
        </div>
    )
}

export default Signup
