import { createSlice } from '@reduxjs/toolkit'

const userStore = createSlice( {
    name: 'userStore',
    initialState: {
        token: '',
        isAffiliate: false,  // 是否是推广者
        affiliate: {}, // 推广者数据
        userInfo: {}, // 用户数据
        orderInfo: {} // 会员信息
    },
    reducers: {
        // TODO 本来就是一个 infos 和 token的，在没有设计好产品故事，越加越多，后面在优化

        //  更新 Token
        updateToken( state, action ) {
            state.token = action.payload
        },
        // 更新 推广者数据
        updateAffiliate( state, action ) {
            state.affiliate = action.payload ? action.payload : state.affiliate
            state.isAffiliate = action.payload ? true : false
        },
        // 更新 用户信息
        updateUserInfo( state, action ) {
            state.userInfo = action.payload
        },
        // 更新 用户订单信息
        updateOrderInfo( state, action ) {
            state.orderInfo = action.payload
        },
        //  清除 数据
        clearUserData( state ) {
            state.token = ''
            state.isAffiliate = false
            state.userInfo = {}
            state.orderInfo = {}
            state.affiliate = {}
        }
    },
    extraReducers: {}
} )
export const { updateToken, updateAffiliate, updateUserInfo, updateOrderInfo, clearUserData } = userStore.actions

export default userStore.reducer
