import React, { useContext } from 'react'
import { Card, Button, Table, Row, Col, Select, Input, Modal, Form, Spin, message, } from 'antd'

import useCopyClipboard from '@/hooks/copyClipboard'
import { TranslationContext, TranslationProvider } from '../../provider/translationProvider'
import './recommend.less'
import CopyIcon from '@/components/Icon/Copy'

// hook
import { useReferralAccounts, useRecommendations, useRegisteredUser, useRecommendedOrder } from './recommend.hook'


const ReferralAccounts = () => {
    const t = useContext( TranslationContext )

    const {
        form,
        loading,
        referralAccountsData,
        visible,
        rulesForm,
        handleModalControl,
        handleFormSubmit
    } = useReferralAccounts( t )

    return (
        <>
            <Card className="referral-accounts" title={ t( 'recommend.referralAccounts.title' ) }>
                <Row gutter={ 40 }>
                    <Col sm={ 6 } xs={ 12 }>
                        <div className="referral-accounts-items">
                            <strong>{ t( 'recommend.referralAccounts.withdrawableAmount' ) }</strong>
                            <p>$ { referralAccountsData.withdrawalAmount }</p>
                        </div>
                    </Col>
                    <Col sm={ 6 } xs={ 12 }>
                        <div className="referral-accounts-items">
                            <strong>{ t( 'recommend.referralAccounts.totalWithdrawnAmount' ) }</strong>
                            <p>$ { referralAccountsData.totalWithdrawnAmount }</p>
                        </div>
                    </Col>
                    <Col sm={ 6 } xs={ 12 }>
                        <div className="referral-accounts-items">
                            <strong>{ t( 'recommend.referralAccounts.referralRegistrations' ) }</strong>
                            <p>{ referralAccountsData.registers }</p>
                        </div>
                    </Col>
                    <Col sm={ 6 } xs={ 12 }>
                        <div className="referral-accounts-items">
                            <strong>{ t( 'recommend.referralAccounts.referralOrders' ) }</strong>
                            <p>{ referralAccountsData.orders }</p>
                        </div>
                    </Col>
                </Row>
                <figure>
                    <img src="null" alt=""/>
                    <figcaption>
                        <h4>{ t( 'recommend.referralAccounts.WithdrawBank' ) }</h4>
                        <p>{ t( 'recommend.referralAccounts.withdrawalInfo' ) }</p>
                    </figcaption>
                    <Button onClick={ handleModalControl }>{ t( 'recommend.referralAccounts.withdraw' ) }</Button>
                </figure>
            </Card>
            <Modal
                title={ t( 'recommend.referralAccountsFrom.title' ) }
                width={ 500 }
                open={ visible }
                footer={ null }
                onCancel={ handleModalControl }>
                <Spin spinning={ loading }>
                    <Form
                        colon={false}
                        form={ form }
                        name="referralAccountsFrom"
                        labelCol={ { span: 8 } }
                        wrapperCol={ { span: 18 } }
                        onFinish={ handleFormSubmit }
                        initialValues={ { withdrawalAmount: referralAccountsData.withdrawalAmount } }
                    >
                        <Form.Item label={ t( 'recommend.referralAccountsFrom.withdrawalAmount' ) }
                                   name="withdrawalAmount" rules={ rulesForm.withdrawalAmount }>
                            <Input placeholder={ t( 'recommend.referralAccountsFrom.withdrawalAmountPlaceholder' ) }
                                   disabled
                                   style={ { width: '100%' } } prefix="$" min={ 0 }/>
                        </Form.Item>
                        <Form.Item label={ t( 'recommend.referralAccountsFrom.paymentChannel' ) }
                                   name="paymentChannel"
                                   rules={ rulesForm.paymentChannel }>
                            <Select placeholder={ t( 'recommend.referralAccountsFrom.paymentChannelPlaceholder' ) }
                                    options={ [
                                        { value: 'Alipay', label: t( 'recommend.referralAccountsFrom.Alipay' ) },
                                        { value: 'USDT', label: 'USDT' },
                                        { value: 'Paypal', label: 'Paypal' },
                                    ] }/>
                        </Form.Item>
                        <Form.Item label={ t( 'recommend.referralAccountsFrom.paymentAccount' ) }
                                   name="paymentAccount"
                                   rules={ rulesForm.paymentAccount }>
                            <Input placeholder={ t( 'recommend.referralAccountsFrom.paymentAccountPlaceholder' ) }/>
                        </Form.Item>
                        <Form.Item label={ t( 'recommend.referralAccountsFrom.accountName' ) } name="accountName"
                                   rules={ rulesForm.accountName }>
                            <Input placeholder={ t( 'recommend.referralAccountsFrom.accountNamePlaceholder' ) }/>
                        </Form.Item>
                        <Form.Item label=" ">
                            <div style={ {textAlign: 'right' } }>
                                <Button disabled={ referralAccountsData.withdrawalAmount < 20 } type="primary"
                                        htmlType="submit">{ t( 'recommend.referralAccountsFrom.withdrawImmediately' ) }</Button>
                            </div>
                        </Form.Item>
                    </Form>

                </Spin>
                <p><em style={ {
                    color: '#ff4d4f',
                    marginRight: '4px',
                    display: 'inline-block'
                } }>*</em>{ t( 'recommend.referralAccountsFrom.tips' ) }</p>
            </Modal>
        </>

    )
}

// 推荐信息
const Recommendations = () => {

    const t = useContext( TranslationContext )

    const { copyClipboard } = useCopyClipboard()

    const {
        form,
        loading,
        visible,
        copyAddress,
        handleModalControl,
        handleFormSubmit,
        columns,
        tableData,
        pagination,
        handlePagination
    } = useRecommendations( t )

    const copyText = t( 'recommend.recommendations.exampleText', { address: copyAddress } )

    return (
        <>
            <Card className="recommendations" title={ t( 'recommend.recommendations.title' ) }>
                <Row gutter={ 20 }>
                    <Col sm={ 12 } xs={ 24 }>
                        <h4>{ t( 'recommend.recommendations.referralLink' ) }</h4>
                        <p>{ t( 'recommend.recommendations.referralDescription' ) }</p>
                        <Input.Group style={ { marginTop: 10 } } compact>
                            <Input style={ { width: 'calc(100% - 80px)' } } defaultValue={ copyAddress }
                                   disabled></Input>
                            <Button type="primary"
                                    onClick={ () => copyClipboard( copyAddress ) }>{ t( 'recommend.recommendations.copy' ) }</Button>
                        </Input.Group>
                    </Col>
                    <Col sm={ 12 } xs={ 24 }>
                        <h4>{ t( 'recommend.recommendations.suggestedText' ) }</h4>
                        <div>
                            <p>{ copyText }</p>
                            <Row>
                                <Col span={ 4 } offset={ 20 }>
                                    <p className="copy-text-btn" onClick={ () => copyClipboard( copyText ) }>
                                        <CopyIcon/>{ t( 'recommend.recommendations.copy' ) }
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <section style={ { marginTop: 20 } }>
                    <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: 8 } }>
                        <h4>{ t( 'recommend.recommendations.channelManagement' ) }</h4>
                        <Button
                            onClick={ handleModalControl }>{ t( 'recommend.recommendations.creationCode' ) }</Button>
                    </div>

                    <Table columns={ columns } dataSource={ tableData } scroll={{ x: true }}
                           pagination={ { ...pagination, showSizeChanger: true, pageSizeOptions: [ 10, 20, 30 ] } }
                           onChange={ handlePagination }/>
                </section>
            </Card>
            <Modal
                title={ t( 'recommend.recommendationsFrom.title' ) }
                open={ visible }
                footer={ null }
                onCancel={ handleModalControl }>
                <Spin spinning={ loading }>
                    <Form
                        form={ form }
                        name="recommendationsFrom"
                        onFinish={ handleFormSubmit }>
                        <Form.Item label={ t( 'recommend.recommendationsFrom.channelName' ) } name="channelName"
                                   rules={ [ {
                                       required: true,
                                       message: t( 'recommend.recommendationsFrom.channelNamePlaceholder' )
                                   } ] }>
                            <Input placeholder={ t( 'recommend.recommendationsFrom.channelNamePlaceholder' ) }/>
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                <Col offset={ 9 }><Button type="primary"
                                                          htmlType="submit">{ t( 'recommend.recommendationsFrom.createCode' ) } </Button></Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

// 注册用户
const RegisteredUser = () => {
    const t = useContext( TranslationContext )
    const { columns, tableData, pagination, handlePagination, optionsList, handleSelectChange } = useRegisteredUser( t )
    // status
    return (
        <Card title={ t( 'recommend.registeredUser.title' ) } extra={
            <Select defaultValue={ 6 } style={ { width: '200px' } } onChange={ handleSelectChange }
                    options={ optionsList }/> }>
            <Table columns={ columns } dataSource={ tableData } scroll={{ x: true }}
                   pagination={ { ...pagination, showSizeChanger: true, pageSizeOptions: [ 10, 20, 30 ] } }
                   onChange={ handlePagination }/>
        </Card>
    )
}

// 推荐订单
const RecommendedOrder = () => {
    const t = useContext( TranslationContext )

    const {
        columns,
        tableData,
        pagination,
        handlePagination,
        optionsList,
        handleSelectChange
    } = useRecommendedOrder( t )

    return (
        <Card title={ t( 'recommend.recommendedOrder.title' ) } extra={
            <Select defaultValue={ 6 } style={ { width: '200px' } } onChange={ handleSelectChange }
                    options={ optionsList }/>
        }>
            <Table columns={ columns } dataSource={ tableData } scroll={{ x: true }}
                   pagination={ { ...pagination, showSizeChanger: true, pageSizeOptions: [ 10, 20, 30 ] } }
                   onChange={ handlePagination }/>
        </Card>
    )
}

const Recommend = () => {

    return (
        <div className="container recommend-container">
            <TranslationProvider>
                {/*推荐账户*/ }
                <ReferralAccounts/>
                {/*推荐信息*/ }
                <Recommendations/>
                <Row gutter={ 20 }>
                    <Col sm={ 12 } xs={ 24 }>
                        {/*注册用户*/ }
                        <RegisteredUser/>
                    </Col>
                    <Col sm={ 12 } xs={ 24 }>
                        {/*推荐订单*/ }
                        <RecommendedOrder/>
                    </Col>
                </Row>
            </TranslationProvider>
        </div>
    )
}
export default Recommend
