// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ced4da;
}
.signup-container figure {
  padding: 4px;
  vertical-align: middle;
  line-height: 30px;
}
.signup-container figure img {
  width: 120px;
  display: inline-block;
}
.signup-container figure figcaption {
  font-weight: 600;
  font-size: 18px;
  padding-left: 8px;
  display: inline-block;
  border-left: 1px solid #666666;
  margin-left: 8px;
  line-height: 20px;
}
.signup-container .google-login {
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #EAEAEA;
  cursor: pointer;
}
.signup-container .google-login img {
  display: inline-block;
  margin-right: 10px;
}
.signup-container .google-login:hover {
  color: #1890ff;
}
`, "",{"version":3,"sources":["webpack://./src/view/Signup/signup.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;AACF;AAPA;EASI,YAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AAZA;EAcM,YAAA;EACA,qBAAA;AACN;AAhBA;EAmBM,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;AAAN;AAzBA;EA8BI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AAFJ;AApCA;EAwCM,qBAAA;EACA,kBAAA;AADN;AAGI;EACE,cAAA;AADN","sourcesContent":[".signup-container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #ced4da;\n\n  figure {\n    padding: 4px;\n    vertical-align: middle;\n    line-height: 30px;\n\n    img {\n      width: 120px;\n      display: inline-block;\n    }\n\n    figcaption {\n      font-weight: 600;\n      font-size: 18px;\n      padding-left: 8px;\n      display: inline-block;\n      border-left: 1px solid #666666;\n      margin-left: 8px;\n      line-height: 20px;\n    }\n  }\n\n  .google-login {\n    width: 100%;\n    height: 36px;\n    line-height: 36px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 2px;\n    border: 1px solid #EAEAEA;\n    cursor: pointer;\n    img {\n      display: inline-block;\n      margin-right: 10px;\n    }\n    &:hover {\n      color: rgba(24, 144, 255, 1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
