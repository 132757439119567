/**
 * @name: index
 * @author: sand
 * @date: 2024-09-03 09:58
 * @description：index
 * @update: 2024-09-03 09:58
 */

import React from 'react'
import DocumentTitle from 'react-document-title'
import { withTranslation } from 'react-i18next'
import { enquireScreen } from 'enquire-js'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './contactUs.less'

let isMobile = false

enquireScreen((b) => {
    isMobile = b
})

class Terms extends React.PureComponent {
    state = {
        isMobile
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            })
        })
    }

    render() {
        const {t} = this.props

        return (
            <DocumentTitle title={ t('contactUs.title') }>
                <div className="contactUs-page">
                    <Header isMobile={ this.state.isMobile }/>
                    <div className="content">
                        <div className="ml-wrapper">
                            <div className="container">
                                <div className="pages ml-title">
                                    <h1>{t('contactUs.title')}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="ml-wrapper pages">
                            <div className="container">
                                <div className="pages">
                                    <h2 className="subtitle-wrap"><span className="desc2">{ t('contactUs.serviceAndComplaints') }</span></h2>
                                    <p className="desc-wrap">
                                        <span className="desc">
                                            <a href="mailto:service@jiashus.com">service@jiashus.com</a>
                                        </span>
                                    </p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{ t('contactUs.businessAndCooperation') }</span></h2>
                                    <p className="desc-wrap">
                                        <span className="desc">
                                           <a href="mailto:tension@muran.org">tension@muran.org</a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </DocumentTitle>
        )
    }
}

export default withTranslation()(Terms)
