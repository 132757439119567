import { message } from 'antd'
import { useTranslation } from 'react-i18next'

const useCopyClipboard = () => {
    const { t } = useTranslation()
    const copyClipboard = async ( text ) => {

        try {
            await navigator.clipboard.writeText( text )
            message.success( t( 'recommend.copySuccess' ) )
        } catch ( err ) {
            console.error( 'Failed to copy: ', err )
        }
    }

    return { copyClipboard }
}


export default useCopyClipboard
