import React from 'react'

import LayoutHeader from '../Header'
import LayoutFooter from '../Footer'

const Layout = ( { children } ) => {

    return (
        <>
            <LayoutHeader/>
            { children }
            <LayoutFooter/>
        </>
    )
}
export default Layout
