/**
 * @name: App
 * @author: sand
 * @date: 2024-08-02 10:29
 * @description：App
 * @update: 2024-08-02 10:29
 */

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import ScrollToTop from '../components/ScrollToTop'
import Layout from '../components/Layout'

import HomePage from './Home'
import TermsPage from './Terms'
import PrivacyPage from './Privacy'
import Recommend from './Recommend' // 返现
import Affiliate from './Affiliate' // 推荐
import MSA from './MSA'
import Login from './Login'
import Signup from './Signup' // 注册
import User from './User' // 个人中心
import ContactUs from './ContactUs' // 联系我们
import Share from './Share' // 分享下载

import '../locales/i18n'

const clientId = '395930842554-r7vfnptc2pl91v935d77ckj6q9tmk02j.apps.googleusercontent.com'

function withLayout( WrappedComponent ) {
    return function ( props ) {
        return (
            <Layout>
                <WrappedComponent { ...props } />
            </Layout>
        )
    }
}

const App = () => (
    <GoogleOAuthProvider clientId={ clientId }>
        <Router>
            <ScrollToTop/>
            <Switch>
                <Route exact path="/" component={ HomePage }/>
                <Route path="/agreement" component={ MSA }/>
                <Route path="/terms" component={ TermsPage }/>
                <Route path="/privacy" component={ PrivacyPage }/>
                <Route path="/login" component={ Login }/>
                <Route path="/signup" component={ Signup }/>
                <Route path="/ContactUs" component={ ContactUs }/>
                <Route path="/share" component={ Share  }/>

                <Route path="/recommend" component={ withLayout( Recommend ) }/>
                <Route path="/affiliate" component={ withLayout( Affiliate ) }/>
                <Route path="/user" component={ withLayout( User ) }/>

            </Switch>
        </Router>
    </GoogleOAuthProvider>
)

export default App
