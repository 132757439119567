import React from 'react'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import { Card, Icon } from 'antd'
import Parallax from "rc-scroll-anim/lib/ScrollParallax"
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next"
import to from 'await-to-js'
// api
import { homeProductList } from '@/api/user'

// store
import { connect } from 'react-redux'


const cardStyle = {
    padding: '0 0 18px 0',
    border: '1px solid #e8e8e8',
    borderImage: 'initial',
    boxShadow: '0 10px 40px -10px rgba(0, 64, 128, .2)'
}

class PriceCard extends React.PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired
    }

    state = {
        virtualSku: []
    }

    componentDidMount() {
        this.sendHomeProductList().then()
    }

    async sendHomeProductList() {
        const [ err, res ] = await to( homeProductList() )
        if ( err ) return
        const { data: { list } } = res
        this.setState( {
            virtualSku: list.map( item => {
                return {
                    ...item,
                    description: [ 0, 1, 2, 3, 4 ]
                }
            } )
        } )
    }

    render() {

        const { t, history } = this.props

        const { virtualSku } = this.state

        const handleBuy = () => {
            this.props.token ? history.push( '/user' ) : history.push( '/login' )
        }


        return (
            <div id="pricing" className="home-page">
                <div className="home-page-wrapper" id="page1-wrapper">
                    { !this.props.isMobile && (
                        <Parallax
                            className="page1-bg"
                            animation={ {
                                translateY: 200,
                                ease: 'linear',
                                playScale: [ 0, 1.65 ]
                            } }
                            location="page1-wrapper"
                        >{ t( 'sku.pricing' ) }</Parallax>
                    ) }
                    <h2>{ t( 'sku.titleFirst' ) }<span> { t( 'name' ) } </span>{ t( 'sku.titleLast' ) }</h2>
                    <div className="title-line-wrapper page1-line">
                        <div className="title-line"/>
                    </div>
                    <OverPack>
                        <QueueAnim key="queues" type="bottom" leaveReverse>
                            <div key="bottom" className="card-wrapper">
                                {
                                    virtualSku.map( ( item, _index ) => (
                                        <div className="card-box" key={ item.name }>
                                            <Card className="card" bordered={ false } bodyStyle={ cardStyle }>
                                                <div className="cardBanner">
                                                    <p className="title">
                                                        { item.price ? `¥${ item.price }` : t( 'sku.list.0.price' ) }
                                                    </p>
                                                    <p className="subTitle"> { item.name }</p>
                                                </div>
                                                <div className="cardContent">
                                                    {
                                                        item.description.map( ( i ) => (
                                                            <p className="item" key={ i }>
                                                                <Icon
                                                                    type={ t( `sku.list.${ _index }.description.${ i }.icon` ) }
                                                                    style={ {
                                                                        fontSize: 16,
                                                                        color: '#F79900'
                                                                    } }/>
                                                                <span
                                                                    className="text1">{ t( `sku.list.${ _index }.description.${ i }.text1` ) }</span>
                                                                <span
                                                                    className="text2">{ t( `sku.list.${ _index }.description.${ i }.text2` ) }</span>
                                                            </p>
                                                        ) )
                                                    }
                                                </div>
                                                <div key="bottom" className="buy-now-box">
                                                    <div className="btn"
                                                         onClick={ handleBuy }>{ t( 'user.buyNow' ) }</div>
                                                </div>
                                            </Card>
                                        </div>
                                    ) )
                                }
                            </div>
                        </QueueAnim>

                    </OverPack>

                </div>

            </div>
        )
    }
}

const mapStateToProps = ( state ) => ( {
    token: state.userStore.token,
} )
const translationPriceCard = withTranslation()( PriceCard )
const withRouterPriceCard = withRouter( translationPriceCard )
export default connect( mapStateToProps )( withRouterPriceCard )
