import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const useAffiliate = () => {
    const userToken = useSelector( ( state ) => state.userStore.token )
    const isAffiliate = useSelector( ( state ) => state.userStore.isAffiliate )

    const history = useHistory()
    const handleRecommend = ( setIsModalOpen ) => {
        if ( isAffiliate ) {
            let jumpUrl
            userToken ? jumpUrl = '/recommend' : jumpUrl = '/login'
            history.push( jumpUrl )
        } else {
            setIsModalOpen( true )
        }
    }
    return { handleRecommend }
}
