import en from './en.json'
import zhCn from './zh-cn.json'
import zhTw from './zh-tw.json'

export default {
    'en': {
        translation: en
    },
    'zh-CN': {
        translation: zhCn
    },
    'zh-TW': {
        translation: zhTw
    },
    'zh-MO': {
        translation: zhTw
    },
    'zh-HK': {
        translation: zhTw
    }
}
