import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, Button, Input, Form, Spin, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
import to from 'await-to-js'
// Api
import { signIn } from '../../api/user'
// less
import './login.less'
// images
import LogoSrc from 'images/logo.png'
import GoogleSrc from 'images/google.svg'
// store
import { useDispatch } from 'react-redux'
import { updateToken, updateAffiliate, updateUserInfo, updateOrderInfo } from '@/store/modules/userStore'

const Login = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const dispatch = useDispatch()

    const [ loading, setLoading ] = useState( false )

    const rulesForm = {
        account: [
            { required: true, message: t( 'login.accountPlaceholder' ) }
        ],
        password: [
            { required: true, message: t( 'login.passwordPlaceholder' ) }
        ],
    }
    // 用户登陆
    const handleFormSubmit = async ( values ) => {
        setLoading( true )
        const params = {
            ...values,
            type: 1,
            openid: ''
        }
        const [ err, res ] = await to( signIn( params ) )
        setLoading( false )
        if ( err ) return
        const { data, msg } = res
        message.success( msg )
        // TODO 本来就是一个 infos 和 token的，在没有设计好产品故事，越加越多，后面在优化
        dispatch( updateToken( data.token ) )
        dispatch( updateUserInfo( data.userInfo ) )
        dispatch( updateAffiliate( data.affiliate ) )
        dispatch( updateOrderInfo( data.orderInfo ) )
        history.push( '/user' )
    }

    const googleLogin = useGoogleLogin( {
        onSuccess: async ( tokenResponse ) => {
            console.log( 'googleLogin', tokenResponse )
            // const userInfo = await axios.get(
            //     'https://www.googleapis.com/oauth2/v3/userinfo',
            //     { headers: { Authorization: 'Bearer <tokenResponse.access_token>' } },
            // );
            //
            // console.log(userInfo);
        },
        onError: errorResponse => console.log( errorResponse ),
    } )


    return (
        <div className="login-container">
            <Spin spinning={ loading }>
                <Card className="login-container-card">
                    <figure>
                        <Link to="/">
                            <img src={ LogoSrc } alt="logo"/>
                        </Link>
                        <figcaption>{ t( 'login.title' ) }</figcaption>
                    </figure>
                    <Form
                        name="loginFrom"
                        onFinish={ handleFormSubmit }>
                        <Form.Item name="account" rules={ rulesForm.account }>
                            <Input placeholder={ t( 'login.account' ) }/>
                        </Form.Item>
                        <Form.Item name="password" rules={ rulesForm.password }>
                            <Input type="password" placeholder={ t( 'login.password' ) }/>
                        </Form.Item>
                        <Form.Item>
                            <Button style={ { width: '100%' } } type="primary"
                                    htmlType="submit">{ t( 'login.title' ) }</Button>
                        </Form.Item>
                    </Form>
                    {/*<p style={ { textAlign: 'right', marginBottom: 0 } }>*/ }
                    {/*    <Link to="/signup">{ t( 'signup.title' ) }</Link>*/ }
                    {/*</p>*/ }

                    <p style={ { textAlign: 'center' } }>or</p>


                    <div className="google-login" onClick={ () => googleLogin() }>
                        <img src={ GoogleSrc } alt=""/>
                        <span>{ t( 'login.googleLogin' ) }</span>
                    </div>
                </Card>
            </Spin>
        </div>
    )
}

export default Login
