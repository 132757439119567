import instance from './config'

const http = {
    get( url, data, config ){
        return instance.get( url, { params: data, ...config } )
    },
    post( url, data, config ){
        return instance.post( url, data, config )
    },
    put( url, data, config ){
        return instance.put( url, data, config )
    },
    patch( url, data, config ){
        return instance.patch( url, data, config )
    },
    delete( url, data, config ){
        return instance.delete( url, { data, ...config } )
    }
}

export default http
