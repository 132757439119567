import React from 'react'

const ResultError = () => {

    return (


        <svg width="261" height="260" viewBox="0 0 261 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                  d="M30.4326 136.74C30.4326 163.261 40.9683 188.697 59.7219 207.45C78.4756 226.204 103.911 236.74 130.433 236.74C156.954 236.74 182.39 226.204 201.143 207.45C219.897 188.697 230.433 163.261 230.433 136.74C230.433 110.218 219.897 84.7825 201.143 66.0288C182.39 47.2752 156.954 36.7395 130.433 36.7395C103.911 36.7395 78.4756 47.2752 59.7219 66.0288C40.9683 84.7825 30.4326 110.218 30.4326 136.74Z"
                  fill="#FF5C51"/>
            <path
                d="M66.4326 213.574C83.7756 228.035 106.087 236.739 130.433 236.739C155.408 236.739 178.244 227.583 195.767 212.446V97.7415H66.4326V213.574Z"
                fill="white"/>
            <path
                d="M196.94 97.7415H182.342C181.888 97.7383 181.451 97.9155 181.127 98.2343C180.803 98.553 180.619 98.9872 180.615 99.4415V107.792C180.619 108.246 180.803 108.68 181.127 108.999C181.451 109.317 181.888 109.495 182.342 109.491H188.574V218.105C192.813 215.07 196.808 211.708 200.524 208.051V101.274C200.511 100.333 200.128 99.4343 199.457 98.7731C198.786 98.112 197.882 97.7414 196.94 97.7415ZM78.5484 97.7645H63.9244C62.9812 97.76 62.0745 98.1289 61.4024 98.7907C60.7303 99.4524 60.3474 100.353 60.3374 101.296V208.051C64.0553 211.706 68.0509 215.067 72.2884 218.104V109.509H78.5444C78.9986 109.513 79.4356 109.335 79.7592 109.017C80.0828 108.698 80.2667 108.264 80.2704 107.809V99.4635C80.2662 99.0102 80.0827 98.577 79.76 98.2587C79.4373 97.9403 79.0017 97.7626 78.5484 97.7645Z"
                fill="#FF5C51"/>
            <path opacity="0.2"
                  d="M89.7764 149.537H173.428C173.67 149.535 173.908 149.485 174.13 149.391C174.352 149.297 174.553 149.16 174.722 148.987C174.891 148.815 175.025 148.611 175.115 148.388C175.205 148.164 175.251 147.925 175.248 147.683V138.63V138.622C175.247 138.131 175.051 137.661 174.703 137.314C174.356 136.968 173.884 136.774 173.393 136.774H89.7684C89.5253 136.775 89.2846 136.823 89.0602 136.917C88.8358 137.01 88.632 137.147 88.4604 137.319C88.2889 137.491 88.153 137.696 88.0605 137.921C87.968 138.145 87.9207 138.386 87.9214 138.629V147.69C87.9219 147.933 87.9703 148.174 88.0638 148.398C88.1573 148.623 88.2941 148.827 88.4664 148.998C88.6386 149.17 88.843 149.306 89.0677 149.398C89.2925 149.491 89.5333 149.538 89.7764 149.537ZM173.395 189.237H89.7704C89.5273 189.238 89.2866 189.286 89.0622 189.38C88.8378 189.473 88.6339 189.61 88.4624 189.782C88.2909 189.954 88.155 190.159 88.0625 190.384C87.97 190.608 87.9227 190.849 87.9234 191.092V200.147C87.9239 200.638 88.1194 201.109 88.4669 201.456C88.8144 201.803 89.2854 201.998 89.7764 201.997H173.393V202.001H173.43C173.917 201.996 174.382 201.798 174.724 201.451C175.065 201.103 175.254 200.634 175.25 200.147V191.092V191.084C175.25 190.841 175.202 190.601 175.108 190.376C175.015 190.152 174.878 189.948 174.706 189.776C174.533 189.605 174.329 189.469 174.104 189.377C173.879 189.284 173.639 189.237 173.395 189.237ZM89.5364 163.027C89.1098 163.031 88.7016 163.201 88.4 163.503C88.0984 163.805 87.9275 164.213 87.9244 164.639V174.176C87.9275 174.603 88.0984 175.011 88.4001 175.312C88.7017 175.614 89.1099 175.785 89.5364 175.787H152.219C152.43 175.788 152.639 175.746 152.833 175.665C153.027 175.584 153.204 175.465 153.352 175.315C153.5 175.165 153.616 174.987 153.695 174.792C153.774 174.596 153.812 174.387 153.809 174.176V164.639C153.806 164.213 153.635 163.805 153.333 163.504C153.032 163.202 152.624 163.031 152.197 163.027H89.5364Z"
                  fill="#FF5C51"/>
            <path
                d="M166.367 110.213H94.4978C94.0471 110.213 93.6151 110.034 93.2964 109.715C92.9777 109.396 92.7984 108.964 92.7979 108.513V95.2645C92.7984 94.8137 92.9777 94.3817 93.2964 94.063C93.6151 93.7443 94.0471 93.565 94.4978 93.5645H166.367C166.817 93.5652 167.249 93.7446 167.568 94.0632C167.887 94.3819 168.066 94.8138 168.067 95.2645V108.513C168.063 108.963 167.882 109.393 167.564 109.711C167.246 110.029 166.816 110.209 166.367 110.213Z"
                fill="#FF5C51"/>
            <path
                d="M119.016 97.2925C119.016 103.596 124.127 108.707 130.432 108.707C136.737 108.707 141.848 103.596 141.848 97.2925C141.848 90.9875 136.737 85.8765 130.432 85.8765C124.127 85.8765 119.016 90.9875 119.016 97.2925ZM160.81 146.832L151.623 137.642C151.303 137.325 150.871 137.147 150.42 137.147C149.969 137.147 149.537 137.325 149.216 137.642L130.432 156.427L111.648 137.642C111.329 137.325 110.896 137.147 110.445 137.147C109.995 137.147 109.562 137.325 109.242 137.642L100.055 146.832C99.7384 147.152 99.5605 147.584 99.5605 148.034C99.5605 148.484 99.7384 148.916 100.055 149.235L118.839 168.018L101.304 185.553C100.986 185.873 100.807 186.305 100.807 186.756C100.807 187.207 100.986 187.64 101.304 187.959L110.49 197.146C110.81 197.465 111.243 197.644 111.694 197.644C112.145 197.644 112.578 197.465 112.897 197.146L130.432 179.612L147.966 197.146C148.286 197.465 148.719 197.644 149.17 197.644C149.621 197.644 150.054 197.465 150.373 197.146L159.56 187.959C159.878 187.64 160.057 187.207 160.057 186.756C160.057 186.306 159.878 185.873 159.56 185.553L142.026 168.018L160.81 149.235C161.126 148.915 161.304 148.484 161.304 148.034C161.304 147.584 161.126 147.153 160.81 146.832Z"
                fill="#FF5C51"/>
            <path opacity="0.5"
                  d="M10.5771 197.465C8.93104 197.465 7.32193 196.977 5.95326 196.063C4.5846 195.148 3.51785 193.848 2.88793 192.328C2.258 190.807 2.09318 189.133 2.41432 187.519C2.73545 185.904 3.52811 184.421 4.69207 183.258C5.85602 182.094 7.33899 181.301 8.95344 180.98C10.5679 180.659 12.2413 180.823 13.7621 181.453C15.2829 182.083 16.5827 183.15 17.4972 184.519C18.4117 185.887 18.8998 187.496 18.8998 189.143C18.8998 191.35 18.023 193.467 16.4622 195.028C14.9014 196.588 12.7844 197.465 10.5771 197.465ZM10.5771 184.204C9.59986 184.202 8.64376 184.489 7.82986 185.03C7.01597 185.571 6.38087 186.341 6.00499 187.243C5.6291 188.145 5.52933 189.139 5.7183 190.097C5.90727 191.056 6.37649 191.937 7.06654 192.629C7.7566 193.321 8.63647 193.793 9.59475 193.985C10.553 194.176 11.5466 194.079 12.4498 193.706C13.3529 193.333 14.125 192.7 14.6682 191.887C15.2114 191.075 15.5014 190.12 15.5014 189.143C15.5014 187.835 14.983 186.581 14.06 185.656C13.1369 184.73 11.8844 184.208 10.5771 184.204Z"
                  fill="#FF5C51"/>
            <path
                d="M246.957 87.7856C246.405 87.7856 245.876 87.5663 245.485 87.1761C245.095 86.7859 244.876 86.2567 244.876 85.7049V66.7152C244.876 66.1634 245.095 65.6341 245.485 65.2439C245.876 64.8537 246.405 64.6345 246.957 64.6345C247.508 64.6345 248.038 64.8537 248.428 65.2439C248.818 65.6341 249.037 66.1634 249.037 66.7152V85.7049C249.037 86.2567 248.818 86.7859 248.428 87.1761C248.038 87.5663 247.508 87.7856 246.957 87.7856Z"
                fill="#FF5C51"/>
            <path
                d="M256.528 78.2978H237.469C236.917 78.2977 236.388 78.0784 235.998 77.6882C235.608 77.298 235.389 76.7689 235.389 76.2172C235.389 75.6654 235.608 75.1363 235.998 74.7461C236.388 74.3559 236.917 74.1366 237.469 74.1365H256.528C257.079 74.1366 257.609 74.3559 257.999 74.7461C258.389 75.1363 258.608 75.6654 258.608 76.2172C258.608 76.7689 258.389 77.298 257.999 77.6882C257.609 78.0784 257.079 78.2977 256.528 78.2978Z"
                fill="#FF5C51"/>
            <path opacity="0.75"
                  d="M215.122 44.9098C213.771 44.9125 212.45 44.5146 211.326 43.7663C210.202 43.0181 209.325 41.9532 208.807 40.7064C208.288 39.4596 208.151 38.087 208.413 36.7622C208.674 35.4375 209.323 34.2201 210.277 33.2643C211.231 32.3085 212.447 31.6571 213.771 31.3927C215.096 31.1282 216.468 31.2625 217.716 31.7787C218.964 32.2948 220.031 33.1695 220.781 34.2921C221.532 35.4147 221.932 36.7347 221.932 38.0851C221.932 39.8927 221.215 41.6265 219.938 42.906C218.662 44.1854 216.929 44.9061 215.122 44.9098ZM215.122 34.0486C214.319 34.0459 213.534 34.2814 212.866 34.7254C212.198 35.1694 211.677 35.8019 211.368 36.5426C211.06 37.2832 210.979 38.0988 211.135 38.8858C211.29 39.6728 211.676 40.3957 212.244 40.963C212.811 41.5303 213.534 41.9164 214.321 42.0722C215.108 42.228 215.924 42.1467 216.664 41.8384C217.405 41.5301 218.037 41.0088 218.481 40.3406C218.925 39.6724 219.161 38.8874 219.158 38.0851C219.158 37.0146 218.733 35.9879 217.976 35.2309C217.219 34.4739 216.192 34.0486 215.122 34.0486Z"
                  fill="#FF5C51"/>
            <path opacity="0.5"
                  d="M10.5771 197.465C8.93104 197.465 7.32193 196.977 5.95326 196.063C4.5846 195.148 3.51785 193.848 2.88793 192.328C2.258 190.807 2.09318 189.133 2.41432 187.519C2.73545 185.904 3.52811 184.421 4.69207 183.258C5.85602 182.094 7.33899 181.301 8.95344 180.98C10.5679 180.659 12.2413 180.823 13.7621 181.453C15.2829 182.083 16.5827 183.15 17.4972 184.519C18.4117 185.887 18.8998 187.496 18.8998 189.143C18.8998 191.35 18.023 193.467 16.4622 195.028C14.9014 196.588 12.7844 197.465 10.5771 197.465ZM10.5771 184.204C9.59986 184.202 8.64376 184.489 7.82986 185.03C7.01597 185.571 6.38087 186.341 6.00499 187.243C5.6291 188.145 5.52933 189.139 5.7183 190.097C5.90727 191.056 6.37649 191.937 7.06654 192.629C7.7566 193.321 8.63647 193.793 9.59475 193.985C10.553 194.176 11.5466 194.079 12.4498 193.706C13.3529 193.333 14.125 192.7 14.6682 191.887C15.2114 191.075 15.5014 190.12 15.5014 189.143C15.5014 187.835 14.983 186.581 14.06 185.656C13.1369 184.73 11.8844 184.208 10.5771 184.204Z"
                  fill="#FF5C51"/>
            <path
                d="M246.957 87.7856C246.405 87.7856 245.876 87.5663 245.485 87.1761C245.095 86.7859 244.876 86.2567 244.876 85.7049V66.7152C244.876 66.1634 245.095 65.6341 245.485 65.2439C245.876 64.8537 246.405 64.6345 246.957 64.6345C247.508 64.6345 248.038 64.8537 248.428 65.2439C248.818 65.6341 249.037 66.1634 249.037 66.7152V85.7049C249.037 86.2567 248.818 86.7859 248.428 87.1761C248.038 87.5663 247.508 87.7856 246.957 87.7856Z"
                fill="#FF5C51"/>
            <path
                d="M256.528 78.2978H237.469C236.917 78.2977 236.388 78.0784 235.998 77.6882C235.608 77.298 235.389 76.7689 235.389 76.2172C235.389 75.6654 235.608 75.1363 235.998 74.7461C236.388 74.3559 236.917 74.1366 237.469 74.1365H256.528C257.079 74.1366 257.609 74.3559 257.999 74.7461C258.389 75.1363 258.608 75.6654 258.608 76.2172C258.608 76.7689 258.389 77.298 257.999 77.6882C257.609 78.0784 257.079 78.2977 256.528 78.2978Z"
                fill="#FF5C51"/>
            <path opacity="0.75"
                  d="M215.122 44.9098C213.771 44.9125 212.45 44.5146 211.326 43.7663C210.202 43.0181 209.325 41.9532 208.807 40.7064C208.288 39.4596 208.151 38.087 208.413 36.7622C208.674 35.4375 209.323 34.2201 210.277 33.2643C211.231 32.3085 212.447 31.6571 213.771 31.3927C215.096 31.1282 216.468 31.2625 217.716 31.7787C218.964 32.2948 220.031 33.1695 220.781 34.2921C221.532 35.4147 221.932 36.7347 221.932 38.0851C221.932 39.8927 221.215 41.6265 219.938 42.906C218.662 44.1854 216.929 44.9061 215.122 44.9098ZM215.122 34.0486C214.319 34.0459 213.534 34.2814 212.866 34.7254C212.198 35.1694 211.677 35.8019 211.368 36.5426C211.06 37.2832 210.979 38.0988 211.135 38.8858C211.29 39.6728 211.676 40.3957 212.244 40.963C212.811 41.5303 213.534 41.9164 214.321 42.0722C215.108 42.228 215.924 42.1467 216.664 41.8384C217.405 41.5301 218.037 41.0088 218.481 40.3406C218.925 39.6724 219.161 38.8874 219.158 38.0851C219.158 37.0146 218.733 35.9879 217.976 35.2309C217.219 34.4739 216.192 34.0486 215.122 34.0486Z"
                  fill="#FF5C51"/>
        </svg>


    )
}

export default ResultError
