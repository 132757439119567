import React from 'react'

const Fault = ()=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.9502 1.05022C12.3407 1.44074 12.3407 2.07391 11.9502 2.46443L8.41478 5.99985L11.9504 9.5355C12.341 9.92602 12.341 10.5592 11.9504 10.9497C11.5599 11.3402 10.9267 11.3402 10.5362 10.9497L7.00057 7.41406L3.46492 10.9497C3.07439 11.3402 2.44123 11.3402 2.05071 10.9497C1.66018 10.5592 1.66018 9.92602 2.05071 9.5355L5.58636 5.99985L2.05094 2.46443C1.66041 2.07391 1.66041 1.44074 2.05094 1.05022C2.44146 0.659693 3.07463 0.659693 3.46515 1.05022L7.00057 4.58564L10.536 1.05022C10.9265 0.659693 11.5597 0.659693 11.9502 1.05022Z"
                  fill="#EB3F31"/>
        </svg>
    )
}
export default Fault
