/**
 * @name: index
 * @author: sand
 * @date: 2024-08-02 10:26
 * @description：index
 * @update: 2024-08-02 10:26
 */

import React from 'react'
import DocumentTitle from 'react-document-title'
import { withTranslation } from 'react-i18next'
import { enquireScreen } from 'enquire-js'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './terms.less'

let isMobile = false

enquireScreen((b) => {
    isMobile = b
})

class Terms extends React.PureComponent {
    state = {
        isMobile
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            })
        })
    }

    render() {
        const {t} = this.props

        return (
            <DocumentTitle title={ t('terms.name') }>
                <div className="terms-page">
                    <Header isMobile={ this.state.isMobile }/>
                    <div className="content">
                        <div className="ml-wrapper">
                            <div className="container">
                                <div className="pages ml-title">
                                    <h1>{t('terms.title')}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="ml-wrapper pages">
                            <div className="container">
                                <div className="pages">
                                    <h2 className="subtitle-wrap">
                                        <span className="desc2">{t('terms.reminder')}</span>
                                    </h2>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section1_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content2')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content3')}</span></p>
                                    <ul className="lst-kix_lsc2gchm3e7k-0 start">
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item1')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item2')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item3')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item4')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item5')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item6')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item7')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item8')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item9')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item10')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item11')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.list_item12')}</span></li>
                                    </ul>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content4')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content5')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section1_content6')}</span></p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section2_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section2_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section2_content2')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section2_content3')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section2_content4')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section2_content5')}</span></p>
                                    <ul className="lst-kix_fnlivz95jk7x-0 start">
                                        <li className="list-wrap"><span className="desc">{t('terms.illegal_content')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.unauthorized_info')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.ipr_infringement')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.malicious_code')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.spam')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.prohibited_content')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.other_violations')}</span></li>
                                    </ul>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section3_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section3_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section3_content2')}</span></p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section4_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section4_content1')}</span></p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section5_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section5_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section5_content2')}</span></p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section6_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section6_content1')}</span></p>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section7_title')}</span></h2>
                                    <h4 className="subtitle-wrap1"><span>{t('terms.refund_policy')}</span></h4>
                                    <p className="desc3"><span>{t('terms.refund_notice')}</span> <span className="f-700">{t('terms.refund_conditions_title')}</span> <span className="f-700">{t('terms.refund_conditions')}</span></p>
                                    <h5 className="subtitle-wrap2"><span className="desc4">{t('terms.refund_conditions_title')}</span></h5>
                                    <ol className="lst-kix_e1gds8ear8tn-0 start" start="1">
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_channels')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_goods')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_price')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_time')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.special_refund')}</span></li>
                                    </ol>
                                    <h5 className="subtitle-wrap3"><span className="desc4">{t('terms.refund_disqualification_title')}</span></h5>
                                    <p className="desc-wrap"><span className="desc">{t('terms.refund_disqualification_notice')}</span></p>
                                    <ol className="lst-kix_q683kg47vhu0-0 start" start="1">
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_disqualification_1')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_disqualification_2')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_disqualification_3')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_disqualification_4')}</span></li>
                                    </ol>
                                    <h5 className="subtitle-wrap3"><span className="desc4">{t('terms.refund_process_title')}</span></h5>
                                    <ol className="lst-kix_lta4xdcym2s1-0 start" start="1">
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_process_1')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_process_2')}</span></li>
                                        <li className="list-wrap"><span className="desc">{t('terms.refund_process_3')}</span></li>
                                    </ol>
                                    <h2 className="subtitle-wrap"><span className="desc1">{t('terms.section8_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section8_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section8_content2')}</span></p>
                                    <h2 className="subtitle-wrap4"><span className="desc1">{t('terms.section9_title')}</span></h2>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section9_content1')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section9_content2')}</span></p>
                                    <p className="desc-wrap"><span className="desc">{t('terms.section9_content3')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </DocumentTitle>
        )
    }
}

export default withTranslation()(Terms)
