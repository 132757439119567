import React from 'react'
import DocumentTitle from 'react-document-title'
import { withTranslation } from 'react-i18next'
import { enquireScreen } from 'enquire-js'

import Header from '../../components/Header'
import Banner from './Banner'
import ApplicationScenario from './ApplicationScenario'
import Download from './download'
import PriceCard from './PriceCard'
import Index from '../../components/Footer'
import '../../style/style'

let isMobile = false

enquireScreen((b) => {
    isMobile = b
})

class Home extends React.PureComponent {
    state = {
        isMobile
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            })
        })
    }

    render() {
        const {t} = this.props

        return (
            <DocumentTitle title={ t('name') }>
                <div>
                    <Header isMobile={ this.state.isMobile }/>
                    <div className="home-wrapper">
                        <Banner isMobile={ this.state.isMobile }/>
                        <ApplicationScenario isMobile={ this.state.isMobile }/>
                        <PriceCard isMobile={ this.state.isMobile }/>
                        <Download/>
                    </div>

                    <Index/>
                </div>
            </DocumentTitle>
        )
    }
}

export default withTranslation()(Home)
