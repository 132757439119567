/**
 * @name: index.jsx
 * @author: sand
 * @date: 2024-08-30 10:21
 * @description：index.jsx
 * @update: 2024-08-30 10:21
 */

import React from 'react'
import DocumentTitle from 'react-document-title'
import { withTranslation } from 'react-i18next'
import { enquireScreen } from 'enquire-js'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './msa.less'

let isMobile = false

enquireScreen((b) => {
    isMobile = b
})

class MSA extends React.PureComponent {
    state = {
        isMobile
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            })
        })
    }

    render() {
        const {t} = this.props

        return (
            <DocumentTitle title={ t('footer.msa') }>
                <div className="privacy-page">
                    <Header isMobile={ this.state.isMobile }/>
                    <div className="content">
                        <div className="ml-wrapper">
                            <div className="container">
                                <div className="pages ml-title">
                                    <h1>{ t('footer.msa') }</h1>
                                </div>
                            </div>
                        </div>

                        <p className="msa-page">
                            { t('msa.update_time') }
                            <br/>
                            <br/>
                            { t('msa.welcome_message') }
                            <br/>
                            <br/>
                            { t('msa.service_description') }
                            <br/>
                            <br/>
                            { t('msa.agreement_title') }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            { t('msa.disagreement_notice') }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            { t('msa.change_rights') }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            { t('msa.minor_notice') }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            { t('msa.service_description_title') }
                            <br/>
                            <br/>
                            { t('msa.vip_definition') }
                            <br/>
                            <br/>
                            { t('msa.vip_service_definition') }
                            <br/>
                            <br/>
                            { t('msa.agreement_relation') }
                            <br/>
                            <br/>
                            { t('msa.rules_description') }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            { t('msa.service_usage_title') }
                            <br/>
                            <br/>
                            { t('msa.service_acquisition') }
                            <br/>
                            <br/>
                            { t('msa.paid_service') }
                            <br/>
                            <br/>
                            { t('msa.legal_compliance') }
                            <br/>
                            <br/>
                            { t('msa.commercial_use_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_software_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_transfer_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.unfair_means_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.service_rule_abuse_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.illegal_sale_statement') }
                            <br/>
                            <br/>
                            { t('msa.usage_principles_title') }
                            <br/>
                            <br/>
                            { t('msa.legal_compliance_usage') }
                            <br/>
                            <br/>
                            { t('msa.personal_non_commercial_use') }
                            <br/>
                            <br/>
                            { t('msa.service_change_rights') }
                            <br/>
                            <br/>
                            { t('msa.service_change_notification') }
                            <br/>
                            <br/>
                            { t('msa.account_title') }
                            <br/>
                            <br/>
                            { t('msa.account_acquisition') }
                            <br/>
                            <br/>
                            { t('msa.account_validity') }
                            <br/>
                            <br/>
                            { t('msa.account_management_notice') }
                            <br/>
                            <br/>
                            { t('msa.account_login') }
                            <br/>
                            <br/>
                            { t('msa.account_login_notice') }
                            <br/>
                            <br/>
                            { t('msa.account_management_and_security') }
                            <br/>
                            <br/>
                            { t('msa.account_responsibility') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_account_use') }
                            <br/>
                            <br/>
                            { t('msa.security_verification') }
                            <br/>
                            <br/>
                            { t('msa.account_activity_responsibility') }
                            <br/>
                            <br/>
                            { t('msa.account_information_query') }
                            <br/>
                            <br/>
                            { t('msa.vip_center_query') }
                            <br/>
                            <br/>
                            { t('msa.login_restriction') }
                            <br/>
                            <br/>
                            { t('msa.device_compatibility') }
                            <br/>
                            <br/>
                            { t('msa.device_management_suggestion') }
                            <br/>
                            <br/>
                            { t('msa.account_independence') }
                            <br/>
                            <br/>
                            { t('msa.account_independence_notice') }
                            <br/>
                            <br/>
                            { t('msa.ownership_notice') }
                            <br/>
                            <br/>
                            { t('msa.ownership_statement') }
                            <br/>
                            <br/>
                            { t('msa.guest_mode') }
                            <br/>
                            <br/>
                            { t('msa.guest_mode_notice') }
                            <br/>
                            <br/>
                            { t('msa.binding_suggestion') }
                            <br/>
                            <br/>
                            { t('msa.vip_benefits_and_extra_payment') }
                            <br/>
                            <br/>
                            { t('msa.vip_benefits') }
                            <br/>
                            <br/>
                            { t('msa.vip_gift_notice') }
                            <br/>
                            <br/>
                            { t('msa.device_system_difference') }
                            <br/>
                            <br/>
                            { t('msa.device_system_difference_notice') }
                            <br/>
                            <br/>
                            { t('msa.software_version_requirement') }
                            <br/>
                            <br/>
                            { t('msa.virtual_products') }
                            <br/>
                            <br/>
                            { t('msa.virtual_products_usage') }
                            <br/>
                            <br/>
                            { t('msa.usage_rules_change') }
                            <br/>
                            <br/>
                            { t('msa.activity_participation') }
                            <br/>
                            <br/>
                            { t('msa.activity_participation_rights') }
                            <br/>
                            <br/>
                            { t('msa.discount_policy') }
                            <br/>
                            <br/>
                            { t('msa.discount_policy_notice') }
                            <br/>
                            <br/>
                            { t('msa.usage_norms') }
                            <br/>
                            <br/>
                            { t('msa.usage_norms_notice') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_access_prohibited') }
                            <br/>
                            <br/>
                            { t('msa.unfair_means_prohibited1') }
                            <br/>
                            <br/>
                            { t('msa.breach_consequence') }
                            <br/>
                            <br/>
                            { t('msa.service_term') }
                            <br/>
                            <br/>
                            { t('msa.service_term_notice') }
                            <br/>
                            <br/>
                            { t('msa.service_interruption') }
                            <br/>
                            <br/>
                            { t('msa.fees_and_cancellation') }
                            <br/>
                            <br/>
                            { t('msa.payment_methods') }
                            <br/>
                            <br/>
                            { t('msa.payment_notice') }
                            <br/>
                            <br/>
                            { t('msa.refund_policy') }
                            <br/>
                            <br/>
                            { t('msa.refund_policy_notice') }
                            <br/>
                            <br/>
                            { t('msa.purchase_verification') }
                            <br/>
                            <br/>
                            { t('msa.fee_adjustment') }
                            <br/>
                            <br/>
                            { t('msa.fee_adjustment_notice') }
                            <br/>
                            <br/>
                            { t('msa.auto_renewal') }
                            <br/>
                            <br/>
                            { t('msa.auto_renewal_definition') }
                            <br/>
                            <br/>
                            { t('msa.billing_cycle') }
                            <br/>
                            <br/>
                            { t('msa.first_month_discount') }
                            <br/>
                            <br/>
                            { t('msa.auto_deduction_rule') }
                            <br/>
                            <br/>
                            { t('msa.auto_deduction_agreement') }
                            <br/>
                            <br/>
                            { t('msa.auto_deduction_notice') }
                            <br/>
                            <br/>
                            { t('msa.auto_deduction_attempts') }
                            <br/>
                            <br/>
                            { t('msa.billing_cycle_activation') }
                            <br/>
                            <br/>
                            { t('msa.cancellation_method') }
                            <br/>
                            <br/>
                            { t('msa.cancellation_notice') }
                            <br/>
                            <br/>
                            { t('msa.behavior_norms_and_penalties') }
                            <br/>
                            <br/>
                            { t('msa.prohibited_behavior') }
                            <br/>
                            <br/>
                            { t('msa.service_content_modification') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_service_transfer') }
                            <br/>
                            <br/>
                            { t('msa.security_measures_breach') }
                            <br/>
                            <br/>
                            { t('msa.ownership_statement_removal') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_public_display') }
                            <br/>
                            <br/>
                            { t('msa.unauthorized_behavior') }
                            <br/>
                            <br/>
                            { t('msa.penalty_measures') }
                            <br/>
                            <br/>
                            { t('msa.violation_agreement') }
                            <br/>
                            <br/>
                            { t('msa.service_termination') }
                            <br/>
                            <br/>
                            { t('msa.service_suspension') }
                            <br/>
                            <br/>
                            { t('msa.compensation_responsibility') }
                            <br/>
                            <br/>
                            { t('msa.service_interruption_and_termination') }
                            <br/>
                            <br/>
                            { t('msa.termination_scenarios') }
                            <br/>
                            <br/>
                            { t('msa.voluntary_termination') }
                            <br/>
                            <br/>
                            { t('msa.violation_termination') }
                            <br/>
                            <br/>
                            { t('msa.legal_compliance_termination') }
                            <br/>
                            <br/>
                            { t('msa.emergency_situation_termination') }
                            <br/>
                            <br/>
                            { t('msa.force_majeure_termination') }
                            <br/>
                            <br/>
                            { t('msa.unavoidable_circumstances') }
                            <br/>
                            <br/>
                            { t('msa.termination_consequences') }
                            <br/>
                            <br/>
                            { t('msa.unused_benefits_cancellation') }
                            <br/>
                            <br/>
                            { t('msa.ongoing_transaction_handling') }
                            <br/>
                            <br/>
                            { t('msa.membership_fee_non_refund') }
                            <br/>
                            <br/>
                            { t('msa.violation_liability') }
                            <br/>
                            <br/>
                            { t('msa.liability_disclaimer') }
                            <br/>
                            <br/>
                            { t('msa.personal_information_protection') }
                            <br/>
                            <br/>
                            { t('msa.personal_information_protection_notice') }
                            <br/>
                            <br/>
                            { t('msa.minors_protection') }
                            <br/>
                            <br/>
                            { t('msa.minors_notice') }
                            <br/>
                            <br/>
                            { t('msa.minor_guidance') }
                            <br/>
                            <br/>
                            { t('msa.guardian_responsibility') }
                            <br/>
                            <br/>
                            { t('msa.online_safety_reminder') }
                            <br/>
                            <br/>
                            { t('msa.notification') }
                            <br/>
                            <br/>
                            { t('msa.notification_methods') }
                            <br/>
                            <br/>
                            { t('msa.notification_importance') }
                            <br/>
                            <br/>
                            { t('msa.contact_jiashu') }
                            <br/>
                            <br/>
                            { t('msa.contact_jiashu_notice') }
                            <br/>
                            <br/>
                            { t('msa.online_support') }
                            <br/>
                            <br/>
                            { t('msa.telephone_support') }
                            <br/>
                            <br/>
                            { t('msa.other_support_methods') }
                            <br/>
                            <br/>
                            { t('msa.support_response') }
                            <br/>
                            <br/>
                            { t('msa.other_terms') }
                            <br/>
                            <br/>
                            { t('msa.governing_law') }
                            <br/>
                            <br/>
                            { t('msa.dispute_resolution') }
                            <br/>
                            <br/>
                            { t('msa.invalidity_of_conflicting_clauses') }
                            <br/>
                            <br/>
                            { t('msa.title_usage') }
                        </p>
                    </div>
                    <Footer/>
                </div>
            </DocumentTitle>
        )
    }
}

export default withTranslation()(MSA)
