import { configureStore } from '@reduxjs/toolkit'

import {
    persistStore, persistReducer,
    // FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist'

import storage from 'redux-persist/lib/storage' //  实现本地存储的模块
import userStore from './modules/userStore'
import systemStore from './modules/systemStore'

const userStoreConfig = {
    key: 'user', // key 的名字
    version: 1, // 版本多少
    storage, //  是否使用 storage 做存储
    // whitelist: [ 'token' ] // 指定模块的某个数据持优化
}
const systemStoreConfig = {
    key: 'system', // key 的名字
    version: 1, // 版本多少
    storage, //  是否使用 storage 做存储
    // whitelist: [ 'language' ] // 指定模块的某个数据持优化
}
const store = configureStore( {
    reducer: {
        userStore: persistReducer( userStoreConfig, userStore ),// 模块数据持久化
        systemStore: persistReducer( systemStoreConfig, systemStore ),// 模块数据持久化
    },
    middleware: ( getDefaultMiddleware ) =>
        getDefaultMiddleware( {
            serializableCheck: false
            // serializableCheck: { // 内部 dispatch 执行
            //     ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
            // }
        } )
} )

const persistor = persistStore( store )
export { store, persistor }
