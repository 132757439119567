import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'

import App from './view/App'

const Element = () => (
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
            <App/>
        </PersistGate>
    </Provider>
)

ReactDOM.render( <Element/>, document.getElementById( 'root' ) )
