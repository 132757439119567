import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Table, Modal } from 'antd'

// Provider
import { TranslationContext, TranslationProvider } from '../../provider/translationProvider'
// style
import './affiliate.less'
// images
import IllustrationSrc from 'images/affiliate/illustration.png'
import HighCashbackRatesSrc from 'images/affiliate/high-cashback-rates.png'
import RealTimeTrackingSrc from 'images/affiliate/real-time-tracking.png'
import UltimateExperienceSrc from 'images/affiliate/ultimate-experience.png'
import LifetimeBenefitsSrc from 'images/affiliate/lifetime-benefits.png'
// hooks
import useCopyClipboard from '@/hooks/copyClipboard'
import { useAffiliate } from './affiliate.hook'


// 横幅标语
const AffiliateBanner = ( { setIsModalOpen } ) => {
    const t = useContext( TranslationContext )
    const { handleRecommend } = useAffiliate()

    return (
        <div className="affiliate-banner-container">
            <div className="container affiliate-banner">
                <Row>
                    <Col sm={ 12 } xs={ 24 }>
                        <h2>{ t( 'affiliate.banner.title' ) }</h2>
                        <p>{ t( 'affiliate.banner.subhead' ) }</p>
                        <div className="btn"
                             onClick={ () => handleRecommend( setIsModalOpen ) }>{ t( 'affiliate.banner.JoinNow' ) }</div>
                    </Col>
                    <Col sm={ 12 } xs={ 24 }>
                        <img src={ IllustrationSrc } alt=""/>
                    </Col>
                </Row>

            </div>
            {/* 加入弹窗 */ }
            <JoinModal/>
        </div>
    )
}
// 加入推荐计划
const AffiliateJoin = () => {
    const t = useContext( TranslationContext )
    return (
        <section className="container affiliate-join">
            <Row gutter={ { sm: 50, xs: 40 } }>
                <Col sm={ 6 } xs={ 12 }>
                    <div className="affiliate-join-item">
                        <figure>
                            <img src={ HighCashbackRatesSrc } alt="HighCashbackRates"/>
                        </figure>
                        <h4>{ t( 'affiliate.join.highCashback' ) }</h4>
                        <p>{ t( 'affiliate.join.highCashbackTips' ) }</p>
                    </div>

                </Col>
                <Col sm={ 6 } xs={ 12 }>
                    <div className="affiliate-join-item">
                        <figure>
                            <img src={ RealTimeTrackingSrc } alt=""/>
                        </figure>
                        <h4>{ t( 'affiliate.join.realTimeTracking' ) }</h4>
                        <p>{ t( 'affiliate.join.realTimeTrackingTips' ) }</p>
                    </div>
                </Col>
                <Col sm={ 6 } xs={ 12 }>
                    <div className="affiliate-join-item">
                        <figure>
                            <img src={ UltimateExperienceSrc } alt=""/>
                        </figure>
                        <h4>{ t( 'affiliate.join.ultimateExperience' ) }</h4>
                        <p>{ t( 'affiliate.join.ultimateExperienceTips' ) }</p>
                    </div>
                </Col>
                <Col sm={ 6 } xs={ 12 }>
                    <div className="affiliate-join-item">
                        <figure>
                            <img src={ LifetimeBenefitsSrc } alt=""/>
                        </figure>
                        <h4>{ t( 'affiliate.join.lifetimeBenefits' ) }</h4>
                        <p>{ t( 'affiliate.join.lifetimeBenefitsTips' ) }</p>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

// 佣金返现
const AffiliateReturnCash = () => {
    const t = useContext( TranslationContext )
    const dataSource = [
        {
            key: '1',
            time: t( 'affiliate.returnCash.monthly' ),
            new: '80%',
            renew: '20%'
        },
        {
            key: '2',
            time: t( 'affiliate.returnCash.quarterly' ),
            new: '40%',
            renew: '20%'
        },
        {
            key: '3',
            time: t( 'affiliate.returnCash.semiAnnual' ),
            new: '30%',
            renew: '20%'
        },
        {
            key: '4',
            time: t( 'affiliate.returnCash.annual' ),
            new: '25%',
            renew: '20%'
        },
    ]

    const columns = [
        {
            title: t( 'affiliate.columns.time' ),
            dataIndex: 'time',
            key: 'time',
            align: 'center',
        },
        {
            title: t( 'affiliate.columns.new' ),
            dataIndex: 'new',
            key: 'new',
            align: 'center',
            render: ( text ) => (
                <span style={ { color: '#FF8C05', fontSize: '18px', fontWeight: '600' } }>{ text }</span> )
        },
        {
            title: t( 'affiliate.columns.renew' ),
            dataIndex: 'renew',
            key: 'renew',
            align: 'center',
            render: ( text ) => (
                <span style={ { color: '#FF8C05', fontSize: '18px', fontWeight: '600' } }>{ text }</span> )

        } ]
    return (
        <div className="container affiliate-return-cash">
            <h2>{ t( 'affiliate.returnCash.title' ) }</h2>
            <div className="title-line-wrapper page1-line">
                <div className="title-line"/>
            </div>
            <Table dataSource={ dataSource } columns={ columns } pagination={ false }/>
        </div>
    )
}

const RecommendNow = ( { setIsModalOpen } ) => {
    const t = useContext( TranslationContext )
    const { handleRecommend } = useAffiliate()
    return (
        <>
            <div className="recommend-now">
                <div className="btn"
                     onClick={ () => handleRecommend( setIsModalOpen ) }>{ t( 'affiliate.recommendNow' ) }</div>
            </div>
            {/* 加入弹窗 */ }
            <JoinModal/>
        </>

    )
}
const JoinModal = ( { isModalOpen, setIsModalOpen } ) => {
    const t = useContext( TranslationContext )
    const { copyClipboard } = useCopyClipboard()

    const emailAddress = 'tension@muran.org'
    return (
        <Modal
            width={ '360px' }
            centered
            title={t('affiliate.join.modalTitle')}
            open={ isModalOpen }
            footer={ null }
            onCancel={ () => setIsModalOpen( false ) }
        >
            <p> {t('affiliate.join.email')} <em
                style={ { color: '#1890ff', fontStyle: 'normal', cursor: 'pointer', textDecoration: 'underline' } }
                onClick={ () => copyClipboard( emailAddress ) }>{ emailAddress }</em></p>
        </Modal>
    )
}
const Affiliate = () => {
    const [ isModalOpen, setIsModalOpen ] = useState( false )
    return (
        <div className="affiliate-container">
            <TranslationProvider>
                {/* 横幅标语 */ }
                <AffiliateBanner setIsModalOpen={ setIsModalOpen }/>
                {/* 加入推荐计划 */ }
                <AffiliateJoin/>
                {/* 佣金返现 */ }
                <AffiliateReturnCash/>
                {/*立即加入*/ }
                <RecommendNow setIsModalOpen={ setIsModalOpen }/>
                {/* 加入弹窗提示 */ }
                <JoinModal isModalOpen={ isModalOpen } setIsModalOpen={ setIsModalOpen }/>
            </TranslationProvider>
        </div>

    )
}

export default Affiliate
