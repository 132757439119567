import React from 'react'
import DocumentTitle from 'react-document-title'
import { withTranslation } from 'react-i18next'
import { enquireScreen } from 'enquire-js'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import './privacy.less'

let isMobile = false

enquireScreen((b) => {
    isMobile = b
})

class Privacy extends React.PureComponent {
    state = {
        isMobile
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            })
        })
    }

    render() {
        const {t} = this.props

        return (
            <DocumentTitle title={ t('privacy.title') }>
                <div className="privacy-page">
                    <Header isMobile={ this.state.isMobile }/>
                    <div className="content">
                        <div className="ml-wrapper">
                            <div className="container">
                                <div className="pages ml-title">
                                    <h1>{ t('privacy.title') }</h1>
                                </div>
                            </div>
                        </div>
                        <div className="ml-wrapper pages">
                            <div className="container">
                                <div className="pages">
                                    <p className="desc-wrap">
                                        <span className="desc2">{ t('privacy.special_note') }</span>
                                    </p>
                                    <p className="desc-wrap2">
                                        <span className="desc2"></span>
                                    </p>
                                    <p className="desc-wrap3">
                                        <span className="desc3">{ t('privacy.policy_name') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.version_date') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.introduction') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.help_info') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_1') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_2') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_3') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_4') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_5') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_6') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_7') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.section_8') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc4">{ t('privacy.commitment_1') }</span>
                                        <span className="desc5">{ t('privacy.commitment_2') }</span>
                                        <span className="desc1">{ t('privacy.commitment_3') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc5">{ t('privacy.inquiries') }</span>
                                    </p>
                                    <p className="desc-wrap5">
                                        <span className="desc"></span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.catalogue') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.legal_basis_title') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc1">{ t('privacy.legal_basis_text') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.scope_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.scope_text_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.scope_text_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.scope_text_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.scope_text_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.scope_text_5') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.info_collection_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc7">{ t('privacy.info_collection_text_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc7">{ t('privacy.info_collection_text_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.info_usage') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.registration_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.user_registration') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.registration_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.third_party_login') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.third_party_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.services_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.acceleration_service') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.acceleration_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.customer_service') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.customer_service_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.targeted_push') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.targeted_push_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.marketing_activity') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.marketing_activity_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.payment_feature') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.payment_feature_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.other_services') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.other_services_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.other_services_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.other_services_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.exceptions_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_7') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_details_8') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.third_party_links') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.cookies_title') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc1">{ t('privacy.cookies_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc1">{ t('privacy.cookies_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc">{ t('privacy.cookies_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc">{ t('privacy.cookies_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc">{ t('privacy.cookies_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap4">
                                        <span className="desc">{ t('privacy.cookies_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.sharing_title') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.sharing_details') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.sharing_exceptions_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_6') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_7') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_8') }</span>
                                    </p>
                                    <p className="desc-wrap6">
                                        <span className="desc5">{ t('privacy.sharing_exceptions_9') }</span>
                                        <span className="desc1">
                                            { t('privacy.sharing_exceptions_10') }
                                        </span>
                                    </p>
                                    <p className="desc-wrap6">
                                        <span
                                            className="desc5">{ t('privacy.sharing_exceptions_11') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.academic_public_use') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.intellectual_property') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.dispute_resolution') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.dispute_resolution_details') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.submit_info') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.transfer_title') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc1">{ t('privacy.transfer_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc5">{ t('privacy.transfer_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc5">{ t('privacy.transfer_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.public_disclosure_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.public_disclosure_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.public_disclosure_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.public_disclosure_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.public_disclosure_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.public_disclosure_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.public_disclosure_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.storage_protection_title') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.storage_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.storage_protection_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.storage_protection_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.storage_protection_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.storage_protection_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span
                                            className="desc">{ t('privacy.storage_protection_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.info_protection_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.info_protection_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.info_protection_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.info_protection_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.info_protection_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.info_protection_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.info_protection_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.info_protection_details_7') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc5">{ t('privacy.info_protection_details_8') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.info_protection_details_9') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.info_protection_details_10') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.info_protection_details_11') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span
                                            className="desc1">{ t('privacy.info_protection_details_12') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.rights_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.rights_details') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.info_access_correction_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.info_access_correction_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.info_access_correction_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.info_access_correction_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.change_scope_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.change_scope_details') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.exceptions_to_consent_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.exceptions_to_consent_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.account_deletion_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.account_deletion_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.account_deletion_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.response_requests_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.response_requests_details') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.minors_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.minors_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.minors_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.minors_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.minors_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.minors_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.minors_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.minors_details_7') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.minors_details_8') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.policy_updates_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.policy_updates_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc">{ t('privacy.policy_updates_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_3') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_4') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_5') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_6') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_7') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_8') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.policy_updates_details_9') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.contact_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.contact_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap">
                                        <span className="desc">{ t('privacy.dispute_resolution_title') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc1">{ t('privacy.dispute_resolution_details_1') }</span>
                                    </p>
                                    <p className="desc-wrap1">
                                        <span className="desc4">{ t('privacy.dispute_resolution_details_2') }</span>
                                    </p>
                                    <p className="desc-wrap2">
                                        <span className="desc10"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </DocumentTitle>
        )
    }
}

export default withTranslation()(Privacy)
