// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body  {
    font-family: PingFang SC,apple-system,BlinkMacSystemFont,Helvetica Neue,Source Han Sans SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
}
:is(p) {
    margin-bottom: 0;
}
:is(ul,ol){
    padding: 0;
}
:is(mark){
    background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/style/reset.css"],"names":[],"mappings":"AAAA;IACI,6KAA6K;AACjL;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,6BAA6B;AACjC","sourcesContent":["body  {\n    font-family: PingFang SC,apple-system,BlinkMacSystemFont,Helvetica Neue,Source Han Sans SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;\n}\n:is(p) {\n    margin-bottom: 0;\n}\n:is(ul,ol){\n    padding: 0;\n}\n:is(mark){\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
