import React from 'react';

// fill="#0D0D0D"
const Copy = () => {
    return (
        <svg t="1723170722451" className="copy-icon"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="4615" width="16" height="16">
            <path
                d="M85.333333 170.666667a85.333333 85.333333 0 0 1 85.333334-85.333334h426.666666a85.333333 85.333333 0 0 1 85.333334 85.333334v170.666666h170.666666a85.333333 85.333333 0 0 1 85.333334 85.333334v426.666666a85.333333 85.333333 0 0 1-85.333334 85.333334H426.666667a85.333333 85.333333 0 0 1-85.333334-85.333334v-170.666666H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V170.666667z m341.333334 512v170.666666h426.666666V426.666667h-170.666666v170.666666a85.333333 85.333333 0 0 1-85.333334 85.333334h-170.666666z m170.666666-85.333334V170.666667H170.666667v426.666666h426.666666z"
                p-id="4616"></path>
        </svg>
    )
}
export default Copy
