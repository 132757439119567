// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/common.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACF;AACE;EAAA;IACE,WAAA;IACA,eAAA;IACA,sBAAA;EAEF;AACF","sourcesContent":[".container {\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 12px;\n\n  @media( max-width: 767px) {\n    width: 100%;\n    padding: 0 12px;\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
