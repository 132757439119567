/**
 * @name: download
 * @author: sand
 * @date: 2024-08-07 10:18
 * @description：download
 * @update: 2024-08-07 10:18
 */

export default (url) => {
    gtag('event', 'file_download')
    const downloadUrl = url ?? 'https://www.jiashus.com/download.html'

    // 创建一个隐藏的链接元素并触发点击事件进行下载
    const link = document.createElement('a')
    link.href = downloadUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
