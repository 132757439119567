import React, { useContext } from 'react'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next';

import './share.less'
import { QRCodeSVG } from 'qrcode.react'
// utils
import downloadFile from '../../utils/download'
// hook
import { useShare } from './share.hook'
import iconSrc from 'images/share/icon.png'
import jiashuSrc from 'images/share/jiashu.png'

const Share = () => {
    const { t } = useTranslation()
    const { appData, loading } = useShare()


    return (
        <Spin spinning={ loading }>
            <div className="share-container">
                <div className="share-content">
                    <img src={ iconSrc } alt=""/>
                    <img src={ jiashuSrc } alt=""/>
                    <div className="versions">
                        <span>{t('share.versions')}: { appData.version }({ `${ ( appData.size / 1024 / 1024 ).toFixed( 2 ) }MB` || 0 })</span>
                    </div>
                    <p className="update-time">{t('share.updateTime')}:{ appData[ 'updated_at' ] }</p>
                    <div className="download-btn" onClick={ () => downloadFile( appData.url ) }>{t('share.download')}</div>
                    {
                        ( appData.url && appData.platform !== 'iOS' && appData.platform !== 'Android' )
                        && <>
                            <div className="qr-code">
                                <QRCodeSVG value={ appData.url } size={ 148 }/>
                            </div>
                            <p className="qr-code-tips">{t('share.downloadTips')}</p>
                        </>
                    }
                    <p className="content">更新内容: { appData.content }</p>
                </div>
            </div>
        </Spin>
    )
}

export default Share
